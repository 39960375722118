import React, { useState, useEffect } from 'react';
import creditlineFormData from '../../../data/products/creditlineMX.json';
import installmentLoanFormData from '../../../data/products/installmentLoanMX.json';
import {
  LoanForm,
  Slider,
  SliderProps,
  Markets,
  currencyFormatMX,
  ColorModeForButton,
} from '@ipf-ui/components-library';
import { swapSubdomain } from '../../../helpers/createMenu';
import { CREDITLINE, INSTALLMENT_LOAN } from '../../../data/constants';

const DIVISOR = 100;
const SAMPLE_PERIOD_VALUE = 60;
const SAMPLE_INTEREST_RATE = 1.5;

type LoanFormMode = {
  mode: typeof CREDITLINE | typeof INSTALLMENT_LOAN;
  disclaimerTrigger: number;
  disclaimerText: string;
};

export type LoanFormWrapperMXProps = {
  sliderVariant: Markets;
  currencySymbol: string;
  interestRate: number;
  amountSlider: SliderProps;
  periodSlider: SliderProps;
  termsDescription: string;
  title: string;
  description: string;
  refundsLabel: string;
  buttonText: string;
  buttonUrl: string;
  anchorTarget?: boolean;
  colorMode?: ColorModeForButton;
  kubernetesNamespace: string;
  backgroundColor?: {
    value: string;
  };
  loanFormDefaultValue?: number;
  termsDisclaimer?: string;
  loanFormMode: LoanFormMode;
};

const LoanFormWrapperMX = ({
  amountSlider,
  termsDescription,
  title,
  description,
  refundsLabel,
  buttonText,
  buttonUrl,
  anchorTarget,
  colorMode,
  kubernetesNamespace,
  loanFormDefaultValue,
  termsDisclaimer,
  loanFormMode,
}: LoanFormWrapperMXProps) => {
  const mode = loanFormMode?.mode;
  const loanFormData = mode === INSTALLMENT_LOAN ? installmentLoanFormData : creditlineFormData;
  const [amountInputValue, setAmountInputValue] = useState<number>(
    loanFormDefaultValue ?? loanFormData[0].sum / DIVISOR
  );

  const creditlineUrlParemeters = `?principal=${amountInputValue}&maturity=${SAMPLE_PERIOD_VALUE}&signupgate=true&homeSlider=false`;
  const installmentLoanUrlParemeters = `?principal=${amountInputValue}&signupgate=true&journey=stl30`;

  const applyButton = `${swapSubdomain(buttonUrl, kubernetesNamespace)}${
    mode === INSTALLMENT_LOAN ? installmentLoanUrlParemeters : creditlineUrlParemeters
  }`;

  const loanValues = loanFormData.map((loanVariant) => {
    return loanVariant.sum / 100;
  });
  const [monthlyPaymentValue, setMonthlyPaymentValue] = useState<number>(0);
  const sliders = [
    {
      ...amountSlider,
      value: amountInputValue,
      marks: loanValues,
      type: Slider.Types.Primary,
      format: currencyFormatMX,
      onChange: (value: number) => setAmountInputValue(value),
    },
  ];

  const loanFormProps = {
    sliders,
    termsDescription,
    termsDisclaimer,
    periodValue: SAMPLE_PERIOD_VALUE,
    interestRate: SAMPLE_INTEREST_RATE,
    format: currencyFormatMX,
    callToAction: {
      title,
      description,
      refundsLabel,
      buttonText,
      colorMode,
      buttonAction: () => window.open(applyButton, anchorTarget === false ? '_blank' : '_self'),
    },
    loanFormMode,
  };

  useEffect(() => {
    const index = loanFormData.findIndex((loanVariant) => {
      return loanVariant.sum / DIVISOR === amountInputValue;
    });
    setMonthlyPaymentValue(loanFormData[index].mmp / DIVISOR);
  }, [amountInputValue]);

  return (
    <>
      <LoanForm {...loanFormProps} market={Markets.Latvia} paymentValue={monthlyPaymentValue} />
    </>
  );
};

export default LoanFormWrapperMX;

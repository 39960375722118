export const TESTING_PREVIEW_DOMAIN = 'https://st08.creditea.com';
export const SIT_PREVIEW_DOMAIN = 'https://sit.creditea.com';
export const UAT_PREVIEW_DOMAIN = 'https://uat.creditea.com';
export const APP_LOCALHOST = 'http://localhost:4200';
export const SANITY_URL = 'https://ipfprod.sanity.studio';
export const SANITY_LOCALHOST = 'http://localhost:3333';
export const SANITY_NONPROD_URL = 'https://sanity-cms.nonprod.ipfdigital.io';
export const SANITY_ST08_URL = 'https://sanity-cms.st08.ipfdigital.io';
export const SANITY_SIT_URL = 'https://sanity-cms.sit.ipfdigital.io';
export const SANITY_UAT_URL = 'https://sanity-cms.uat.ipfdigital.io';
export const MOBILE_WALLET_ANDROID_LINK =
  'https://play.google.com/store/apps/details?id=com.ipfdigital.creditea.mw';
export const MOBILE_WALLET_IOS_LINK =
  'https://apps.apple.com/countryCode/app/creditea/id1546903528';
export const MOBILE_WALLET_DYNAMIC_LINK = 'https://m.creditea.com/mw';
export const FRONTEND_HOST_URL = 'https://web-creditea-MARKET.ENV.ipfdigital.io';

export const CREDITLINE = 'creditLine';
export const INSTALLMENT_LOAN = 'installmentLoan';
